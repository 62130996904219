import React from 'react';
import { Link } from 'react-router-dom';

const Products = () => {
  return (
    <div style={{
      fontFamily: 'Helvetica, Arial, sans-serif',
      backgroundColor: '#000000',
      color: '#ffffff',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: '20px'
    }}>
      <h1 style={{
        fontSize: 'clamp(2rem, 8vw, 5rem)',
        marginBottom: '20px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
        color: '#007bff',
        textShadow: '0 0 10px rgba(0,123,255,0.5)'
      }}>
        Under Development
      </h1>
      
      <p style={{
        fontSize: 'clamp(1rem, 3vw, 1.5rem)',
        maxWidth: '800px',
        marginBottom: '40px',
        lineHeight: '1.6',
        color: '#007bff'
      }}>
        We're building the future of searching for what you truly want.
      </p>
      
      <div style={{
        width: '150px',
        height: '150px',
        position: 'relative',
        marginBottom: '40px'
      }}>
        <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          border: '4px solid #007bff',
          borderRadius: '15px',
          animation: 'rotate 3s linear infinite'
        }} />
        <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          border: '4px solid #007bff',
          borderRadius: '15px',
          animation: 'rotate 3s linear infinite, shrink 3s ease-in-out infinite alternate'
        }} />
      </div>
      
      <p style={{
        fontSize: 'clamp(1rem, 2.5vw, 1.3rem)',
        maxWidth: '600px',
        marginBottom: '30px',
        color: '#007bff'
      }}>
        Secure your spot on our exclusive waiting list. Be among the first to experience our Ai searching platform for professional opportunities and networking with like minded people.
      </p>
      
      <Link to="https://tally.so/r/3N0zZN" style={{
        backgroundColor: '#007bff',
        color: 'white',
        padding: '15px 30px',
        fontSize: 'clamp(1rem, 2vw, 1.2rem)',
        textDecoration: 'none',
        borderRadius: '30px',
        fontWeight: 'bold',
        transition: 'all 0.3s ease',
        boxShadow: '0 4px 6px rgba(0, 123, 255, 0.3)'
      }}>
        Join the Waiting List
      </Link>
      
      <style>{`
        @keyframes rotate {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        @keyframes shrink {
          0% { transform: scale(1) rotate(0deg); }
          100% { transform: scale(0.5) rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

export default Products;