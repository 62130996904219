import React from 'react';
import './projectai.css';
import { FaReddit, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const ProjectAI = () => {
  return (
    <div className="content">
      <div className="animated-rectangle">
        <h1>Project AI</h1>
        <p>
          Welcome to Project AI, a groundbreaking platform dedicated to fostering innovation and 
          sustainability in the realm of artificial intelligence. Imagine a space where visionary 
          minds and cutting-edge ideas converge to shape a better future. That's the essence of Project AI.
        </p>
      </div>

      <h2>Our Mission</h2>
      <p>
        In a rapidly evolving technological landscape, we recognized the transformative potential 
        of AI and the pressing need for responsible development, collaborative efforts, and shared 
        resources. Project AI was conceived as a dynamic community where innovation flourishes 
        and sustainability is paramount.
      </p>

      <h2>What We Offer</h2>
      <ul>
        <li>A Network of Innovators: Connect with peers who share your passion for AI and innovation.</li>
        <li>AI Matchmaking: Find the ideal partner for your next ambitious project or collaboration.</li>
        <li>Endless Opportunities: Access a wealth of job openings, projects, and open-source initiatives.</li>
        <li>Curated News: Stay informed with the latest developments and innovations in the AI sector.</li>
        <li>AI Tools and Resources: Access a rich repository of AI models and engines to accelerate your projects.</li>
      </ul>

      <h2>Join Us</h2>
      <p>
        At Project AI, we believe in the power of community. By collaborating and sharing resources, 
        we can push the boundaries of what's possible and create a future where AI benefits all. 
        Join us in shaping the future of artificial intelligence.
      </p>

      <div className="social-media-bar">
        <a href="https://www.reddit.com/r/PROJECT_AI" target="_blank" rel="noopener noreferrer">
          <FaReddit />
        </a>
        <a href="https://twitter.com/YNW_PROJECT_AI" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a>
        <a href="https://www.linkedin.com/company/your-new-way" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
        <a href="https://www.instagram.com/ynw.project.ai" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      </div>
    </div>
  );
};

export default ProjectAI;