import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const slides = [
    {
      title: "Join Your New Way",
      description: "Your New Way of searching for professional opportunities and connections. Join our waiting list below.",
      buttonText: "Join the waiting list",
      buttonLink: "https://tally.so/r/3N0zZN",
      backgroundStyle: {
        background: `
          linear-gradient(45deg, #ff00ff, #00ffff, #ff00ff, #ffff00),
          linear-gradient(-45deg, #ff0000, #00ff00, #0000ff, #ff0000)
        `,
        backgroundBlendMode: 'difference',
        backgroundSize: '400% 400%, 400% 400%',
        animation: 'gradient 15s ease infinite',
      }
    },
    {
      title: "About Your New Way",
      description: "Our mission for the future of AI",
      buttonText: "Learn more",
      buttonLink: "/about",
      backgroundStyle: {
        background: `
          linear-gradient(45deg, #0000FF, #00BFFF, #1E90FF, #4169E1),
          linear-gradient(-45deg, #0000CD, #4682B4, #1E90FF, #00FFFF)
        `,
        backgroundBlendMode: 'difference',
        backgroundSize: '400% 400%, 400% 400%',
        animation: 'gradient 15s ease infinite',
      }
    },
    {
      title: "Project Ai",
      description: "Stay up to date with the latest progression in our project! ",
      buttonText: "Explore",
      buttonLink: "/projectai",
      backgroundStyle: {
        background: `
          linear-gradient(45deg, #FF4500, #FF6347, #FF7F50, #FFA500),
          linear-gradient(-45deg, #FF8C00, #FFD700, #FF6347, #FF4500)
        `,
        backgroundBlendMode: 'difference',
        backgroundSize: '400% 400%, 400% 400%',
        animation: 'gradient 15s ease infinite',
      }
    }
  ];

  const handleSlideChange = (direction) => {
    const itemCount = slides.length;
    setActiveSlide((prevSlide) =>
      direction === 'next'
        ? (prevSlide + 1) % itemCount
        : (prevSlide - 1 + itemCount) % itemCount
    );
  };

  return (
    <div style={{ 
      height: '100vh', 
      width: '100vw',
      overflow: 'hidden', 
      backgroundColor: 'black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      position: 'relative',
    }}>
      <style>
        {`
          @keyframes gradient {
            0% { background-position: 0% 50%, 100% 50%; }
            50% { background-position: 100% 50%, 0% 50%; }
            100% { background-position: 0% 50%, 100% 50%; }
          }
        `}
      </style>
      <div style={{
        width: '80%',
        height: '80%',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '20px',
        boxShadow: '0 0 30px rgba(255, 255, 255, 0.1)'
      }}>
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            transition: 'transform 0.5s ease',
            transform: `translateX(-${activeSlide * 100}%)`,
          }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              style={{
                flex: '0 0 100%',
                height: '100%',
                width: '100%',
                ...slide.backgroundStyle,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                padding: '20px',
                position: 'relative',
              }}
            >
              <h2 style={{
                fontSize: 'clamp(2rem, 4vw, 3.5rem)',
                marginBottom: '20px',
                fontWeight: '700',
                color: 'white',
                letterSpacing: '-0.02em',
              }}>
                {slide.title}
              </h2>
              <p style={{
                fontSize: 'clamp(1rem, 1.75vw, 1.35rem)',
                maxWidth: '80%',
                marginBottom: '30px',
                lineHeight: '1.6',
                fontWeight: '400',
                color: 'rgba(255, 255, 255, 0.9)',
              }}>
                {slide.description}
              </p>
              {slide.buttonLink.startsWith('http') ? (
                <a
                  href={slide.buttonLink}
                  style={{
                    display: 'inline-block',
                    padding: '12px 24px',
                    fontSize: 'clamp(0.8rem, 1.2vw, 1rem)',
                    textDecoration: 'none',
                    fontWeight: '500',
                    color: 'white',
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    borderRadius: '25px',
                    border: '1px solid rgba(255, 255, 255, 0.3)',
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {slide.buttonText}
                </a>
              ) : (
                <Link
                  to={slide.buttonLink}
                  style={{
                    display: 'inline-block',
                    padding: '12px 24px',
                    fontSize: 'clamp(0.8rem, 1.2vw, 1rem)',
                    textDecoration: 'none',
                    fontWeight: '500',
                    color: 'white',
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    borderRadius: '25px',
                    border: '1px solid rgba(255, 255, 255, 0.3)',
                  }}
                >
                  {slide.buttonText}
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
      <button
        style={{
          position: 'absolute',
          top: '50%',
          left: '5%',
          transform: 'translateY(-50%)',
          background: 'black',
          color: 'white',
          border: 'none',
          fontSize: '2.5rem',
          cursor: 'pointer',
          opacity: '0.7',
          zIndex: 10,
          width: '50px',
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
        }}
        onClick={() => handleSlideChange('prev')}
      >
        ‹
      </button>
      <button
        style={{
          position: 'absolute',
          top: '50%',
          right: '5%',
          transform: 'translateY(-50%)',
          background: 'black',
          color: 'white',
          border: 'none',
          fontSize: '2.5rem',
          cursor: 'pointer',
          opacity: '0.7',
          zIndex: 10,
          width: '50px',
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
        }}
        onClick={() => handleSlideChange('next')}
      >
        ›
      </button>
    </div>
  );
};

export default Home;
