import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, NavLink } from 'react-router-dom';
import { FaReddit, FaLinkedin, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Analytics } from '@vercel/analytics/react';
import Home from './pages/home';
import Products from './pages/products';
import About from './pages/about';
import ProjectAI from './pages/projectai';
import './App.css';

const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="header-content">
            <Link to="/" className="logo" onClick={closeMenu}>YNW</Link>
            <nav className={`nav-container ${menuOpen ? 'active' : ''}`}>
              <NavLink to="/" end onClick={closeMenu}>home</NavLink>
              <NavLink to="/products" onClick={closeMenu}>products</NavLink>
              <NavLink to="/about" onClick={closeMenu}>about</NavLink>
              <NavLink to="/projectai" onClick={closeMenu}>project ai</NavLink>
            </nav>
            <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </header>

        <main className="App-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/about" element={<About />} />
            <Route path="/projectai" element={<ProjectAI />} />
          </Routes>
        </main>

        <footer className="App-footer">
          <div className="footer-line"></div>
          <div className="footer-content">
            <div className="footer-columns">
              <div className="footer-column">
                <h3><Link to="/about">About</Link></h3>
                <ul>
                  <li><Link to="/about#mission">our mission</Link></li>
                  <li><Link to="/about#vision">our vision</Link></li>
                </ul>
              </div>
              <div className="footer-column">
                <h3><Link to="/products">Products</Link></h3>
                <ul>
                  <li><Link to="/products#ai-search">ai search engine</Link></li>
                  <li><Link to="/products">profinder</Link></li>
                </ul>
              </div>
              <div className="footer-column">
                <h3><Link to="/about">Get in Touch</Link></h3>
                <ul>
                  <li><a href="https://tally.so/r/wLG15y" target="_blank" rel="noopener noreferrer">Contact</a></li>
                  <li><a href="https://tally.so/r/waGyev" target="_blank" rel="noopener noreferrer">Career</a></li>
                </ul>
              </div>
              <div className="footer-column">
                <h3><Link to="/projectai">Project AI</Link></h3>
                <ul>
                  <li><Link to="/projectai#what-we-do">what we do</Link></li>
                  <li><Link to="/projectai#about">what is it about</Link></li>
                </ul>
              </div>
            </div>
            <div className="footer-bottom">
              <span>your new way © 2024</span>
              <div className="social-icons">
                <a href="https://www.reddit.com/r/PROJECT_AI/" target="_blank" rel="noopener noreferrer"><FaReddit /></a>
                <a href="https://nl.linkedin.com/in/your-new-way-3a3104315" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                <a href="https://x.com/YNW_PROJECT_AI" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                <a href="https://www.instagram.com/ynw.project.ai/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
              </div>
            </div>
          </div>
        </footer>
        <Analytics />
      </div>
    </Router>
  );
};

export default App;