import React from 'react';
import './about.css'; // Importeer je aangepaste CSS-bestand voor de about sectie

const About = () => {
  return (
    <div className="about-container">
      <div className="animated-rectangle">
        <h1>About Us</h1>
        <p>Welcome to Your New Way, where we are revolutionizing the way we develop ourselves.</p>
      </div>
      <div className="about-section">
        <h2>Our Vision</h2>
        <p>At Your New Way, we love to build. We're driven by innovation and creativity and we make sure our core identity is found in all of our work. Our vision helps to empower individuals and organizations to achieve their fullest potential. We strive to be a key factor in the sustainable innovation of our society and planet. Our goal is to see everyone win: everyone must be healthy, wealthy, and live a life full of joy and laughter. We're a young startup setting the standard for a lot more to come.</p>
      </div>
      <div className="about-section">
        <h2>Our Goals</h2>
        <ul>
          <li>
            <span>Innovate</span> Build meaningful solutions that are truly innovative.
          </li>
          <li>
            <span>Connect</span> We're building a network and community of the best individuals and organisations from any given professional and educational background to make sure we can all contribute.
          </li>
          <li>
            <span>Develop</span> We are ready to show the world what we're capable of by showcasing our products and services that will revolutionize many fields and sectors.
          </li>
        </ul>
      </div>
      <div className="about-section">
        <h2>Contact Us</h2>
        <p>We'd love to hear from you! Whether you have questions, feedback, or partnership inquiries, feel free to reach out to us.</p>
        <p><a href="https://tally.so/r/wLG15y">Contact Us Here</a></p>
      </div>
      <div className="about-section">
        <h2>Careers</h2>
        <p>Join our dynamic team and be a part of the AI revolution. We're looking for passionate, forward-thinking individuals who are ready to make an impact. Explore our current job openings <a href="https://tally.so/r/waGyev">here</a> and start your journey with Your New Way today.</p>
      </div>
    </div>
  );
};

export default About;
